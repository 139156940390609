// src/pages/Login.js
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import axios from 'axios';
import '../config/env.js';

function Login({ setToken }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { login } = useContext(UserContext);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${window._env_.REACT_APP_API_BASE_URL}/api/auth/login`, { email, password });
      const token = response.data.token;
      login(token); // Updating user context with decoded data
      setToken(token); // Setting token in state or context for further use
      localStorage.setItem('token', token); // Persisting token in localStorage
      navigate('/'); // Redirecting to the home page after successful login
    } catch (error) {
      console.error('Login failed:', error.response ? error.response.data.message : 'Server error');
      setError('התחברות נכשלה. אנא בדוק את הפרטים ונסה שוב.');
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center" dir="rtl" style={{ backgroundColor:"#1FB5A3", height: "100%" }}>
      <form onSubmit={handleSubmit}  style={{
        maxWidth: '280px',
        padding: '20px'        
      }}>
        <div className="form-group mb-3">
          <input type="email" id="inputEmail" className="form-control" placeholder="כתובת אימייל" required autoFocus value={email} onChange={e => setEmail(e.target.value)} />
        </div>
        <div className="form-group mb-3">
          <input type="password" id="inputPassword" className="form-control" placeholder="סיסמה" required value={password} onChange={e => setPassword(e.target.value)} />
        </div>
        <div className="text-center">
          <button className="btn btn-lg" type="submit" style={{ backgroundColor: '#103C6E', color: 'white', width: '100%' }}>התחבר</button>
        </div>
        <div style={{height: "150px"}}> 
        </div>
        {error && <div style={{ color: 'red', marginTop: '10px', textAlign: 'center', fontSize: '0.9em' }}>{error}</div>}
      </form>
    </div>
  );
}

export default Login;