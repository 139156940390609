// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --bs-body-color: #103C6E;
  --bs-heading-color: #103C6E;
}

html, body {  
  height: 100%;
  overflow: hidden;
  scroll-behavior: smooth;
  margin: 0 auto;
  padding: 0;
  font-family: Arial, sans-serif;  
}

body {
  direction: rtl;
  color: #103C6E;
}

/* Override Bootstrap's default left-to-right */
.modal-content,
.dropdown-menu,
.list-group {
  direction: rtl;
  text-align: right;
}

/* Ensure MUI Dialog components are right-to-left */
.MuiDialog-paper {
  direction: rtl;
  color: #103C6E;
}

.MuiDialogTitle-root,
.MuiDialogContent-root,
.MuiDialogActions-root {
  text-align: right;
  color: #103C6E;
}

/* Reverse flex direction for dialog actions */
.MuiDialogActions-root {
  flex-direction: row-reverse;
}

/* Adjust input fields for RTL */
.MuiInputBase-root {
  text-align: right;
}

.MuiInputLabel-root {
  right: 0;
  left: auto;
  transform-origin: top right;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,2BAA2B;AAC7B;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,uBAAuB;EACvB,cAAc;EACd,UAAU;EACV,8BAA8B;AAChC;;AAEA;EACE,cAAc;EACd,cAAc;AAChB;;AAEA,+CAA+C;AAC/C;;;EAGE,cAAc;EACd,iBAAiB;AACnB;;AAEA,mDAAmD;AACnD;EACE,cAAc;EACd,cAAc;AAChB;;AAEA;;;EAGE,iBAAiB;EACjB,cAAc;AAChB;;AAEA,8CAA8C;AAC9C;EACE,2BAA2B;AAC7B;;AAEA,gCAAgC;AAChC;EACE,iBAAiB;AACnB;;AAEA;EACE,QAAQ;EACR,UAAU;EACV,2BAA2B;AAC7B","sourcesContent":[":root {\n  --bs-body-color: #103C6E;\n  --bs-heading-color: #103C6E;\n}\n\nhtml, body {  \n  height: 100%;\n  overflow: hidden;\n  scroll-behavior: smooth;\n  margin: 0 auto;\n  padding: 0;\n  font-family: Arial, sans-serif;  \n}\n\nbody {\n  direction: rtl;\n  color: #103C6E;\n}\n\n/* Override Bootstrap's default left-to-right */\n.modal-content,\n.dropdown-menu,\n.list-group {\n  direction: rtl;\n  text-align: right;\n}\n\n/* Ensure MUI Dialog components are right-to-left */\n.MuiDialog-paper {\n  direction: rtl;\n  color: #103C6E;\n}\n\n.MuiDialogTitle-root,\n.MuiDialogContent-root,\n.MuiDialogActions-root {\n  text-align: right;\n  color: #103C6E;\n}\n\n/* Reverse flex direction for dialog actions */\n.MuiDialogActions-root {\n  flex-direction: row-reverse;\n}\n\n/* Adjust input fields for RTL */\n.MuiInputBase-root {\n  text-align: right;\n}\n\n.MuiInputLabel-root {\n  right: 0;\n  left: auto;\n  transform-origin: top right;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
